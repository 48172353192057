<template>
  <form-consulta-cadastro
    :formCadastro="formCadastro"
    :controller="controller"
  />
</template>

<script>
import { ConsultaAtendimentoPresencial } from '@/proxy-api/src/services/classes/AtendimentoPresencial/ConsultaAtendimentoPresencial';

import FormConsultaCadastro from '@/components/form/FormConsultaCadastro';
import FormAtendimentoPresencial from '@/views/atendimentoPresencial/form/FormAtendimentoPresencial';

export default {
  components: { FormConsultaCadastro },

  data: function () {
    return {
      controller: new ConsultaAtendimentoPresencial(),
      formCadastro: FormAtendimentoPresencial,
    };
  },
};
</script>
